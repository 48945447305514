@import '../../globalstyled/common/sass/variables.scss';

.calender-wrap{
    & .calender-container{
        & .fc-media-screen {
            & .fc-header-toolbar{
                
                & .fc-toolbar-chunk{

                }
                & button{
                    background-color:$thema-main;
                    border-color: $thema-main;
                }
            }
        }
    }
}
@media only screen and (max-width: 1200px) {
    
}

@media only screen and (max-width: 900px) {
    
}

@media only screen and (max-width: 600px) {
    .fc-media-screen {
        & .fc-header-toolbar{
            display: block;
            margin:auto;
        }
    }
}
@media only screen and (max-width: 400px) {
    
}