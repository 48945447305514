@import '../../globalstyled/common/sass/variables.scss';

.standard-input-wrapper { 
    position:relative; 
    margin-bottom:20px; 
  }
  .standard-lable-input , .floating-select {
    font-size:14px;
    padding:4px 4px;
    display:block;
    width:100%;
    height:30px;
    background-color: transparent;
    border:none;
    border-bottom:1px solid #757575;
  }
  
  .standard-lable-input:focus , .floating-select:focus {
       outline:none;
       border-bottom:2px solid #2196F3; 
  }
  .standard-label {
    color:#999; 
    font-size:14px;
    font-weight:normal;
    position:absolute;
    pointer-events:none;
    left:5px;
    top:5px;
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
  }
  .standard-lable-input:focus ~ label, .standard-lable-input:not(:placeholder-shown) ~ label {
    top:-10px;
    font-size:12px;
    color:#5264AE;
  }
  .floating-select:focus ~ label , .floating-select:not([value=""]):valid ~ label {
    top:-18px;
    font-size:14px;
    color:#5264AE;
  }
  .standard-lable-input:focus ~ .bar:before, .standard-lable-input:focus ~ .bar:after, .floating-select:focus ~ .bar:before, .floating-select:focus ~ .bar:after {
    width:50%;
  }
  .standard-lable-input:focus ~ .highlight , .floating-select:focus ~ .highlight {
    -webkit-animation:inputHighlighter 0.3s ease;
    -moz-animation:inputHighlighter 0.3s ease;
    animation:inputHighlighter 0.3s ease;
  }
