.allcampaigndownloadbtnContainer{
    margin-top:2rem;
    margin-bottom: 22rem;
    display: flex;
    // justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width:100%;
    background-color:#fff;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px #00000033, 0px 1px 1px 0px #00000024, 0px 1px 3px 0px #0000001f;

    & .wrapDnloadContainer {
        display:flex;
        justify-content: space-between;
        align-items: center;
        width:100%;
        margin-bottom: 1rem;
        padding:1rem 0.9rem;
        &.header{
            // background-color: #272727;
            // color:white;
            font-weight: 600;  
            border-bottom: 1px solid gray;  
            font-size: 20px;        
        }
    }
    & .notfound-downloadlink{
        margin:5rem 1rem;
    }
}
