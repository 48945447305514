

$thema-main-color:#fff;
$thema-main-dark-color:#000;

$thema-primary-text-color:#000;
$thema-primary-bg-color:#fff;
$thema-primary-btn-bg-color:#000;
$thema-primary-btn-text-color:#fff;

$thema-secondary-text-color:#fff;
$thema-secondary-bg-color:#000;
$thema-secondary-btn-bg-color:#fff;
$thema-secondary-btn-text-color:#000;


$thema-light:#42a5f5;
$thema-main:#1976d2;
$thema-dark:#1565c0;

$thema-gray-light:#a8a8a8;
$thema-gray:#808080;
$thema-dark-gray:#313131;

/*Search input */
$thema-base-bg:#ffffff26;
$thema-base-change:#ffffff40;

/* Slider */
$thema-slider-navigation-bg:#ffffff3b;


/* Shadow */
$thema-slider-navigation-shadow:0 2px 5px #aeaeae;












$main-body-bg: #f9f8f8;
$main-bg-primary: #fff;
$main-color: #4267b2;

$tab-txt-secondary:#4296f3;

$default-white:#fff;

$default-txt-primary:#fff;
$default-button-primary:#1976d2;
$warning-button-primary:#f24d60;
$darkorange:#ff8c00;
$default-border-radius: 4px;
$accordian-header:#f5f5f5;

$default-tab-head-bottom:#0000001f;



/* font size */
$fs-normal:1rem;
$fs-small:0.5rem;
$fs-big:2rem;
$fs-dot7:0.7rem;
$fs-1five:1.5rem;

