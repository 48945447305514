@import '../../globalstyled/common/sass/variables.scss';


.cart-wrapper{
    display:flex;
    margin:auto;
    width: 88%;
    margin-top:72px;
    background-color: $main-bg-primary;
    padding:0.5em;
    border-radius: $default-border-radius;
}
.cart-grid-section{}

.category{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    }

.cart-item-container{
    margin-top:1.5rem;
}
.head-rate-container{
    display: flex;
    justify-content: space-between;
}

.item-rate-container{
    justify-content: space-between;
   & .item-rate-head{
        & p{
            font-size: 0.9rem;
        }
    }
    
  &  .item-rate-body{
        & p{
            font-size: 0.9rem;
            position: relative;
        }
        & p::after{
            content:':';
            height:10px;
            width: 10px;
            position: absolute;
            top:0;
            left:-10px;
        }
    }
}
.cart-item-img-wrap{
    width: 100%;
    & .cart-item-img{
        max-width: 140px;
    }
    & p{
        font-size: 1rem;
    }
}
.day-field-container{
    text-align:center;
    & .day-field{
        max-width: 100px;
    }
}
.dlt-btn{
    cursor:pointer;
}

.MuiGrid-root.cart-empty-section{
    height: 400px;
    margin-top:100px;
  }

.cart-head-item{
    & div {
        & p{
            margin-bottom: 0;
            font-weight: 700;
        }
    }
}
.cart-total-price{
    display: flex;
    & p{
        margin-bottom:0;
    }
}
.quantity-container{
    text-align: center;
}
.datepickercontainer{
    display:flex;
    justify-content: space-evenly;
}
// .datereset{
//     position: absolute;
//     top:0;
//     right:0;
// }

.nameprefixcontainer{
    display: flex;
    & p{
        padding-top:20px;
    }
}










@media only screen and (max-width: 900px) {
    .cart-wrapper{
        display:flex;
        margin:auto;
        width: 98%;
        margin-top:72px;
        background-color: $main-bg-primary;   
    }
    
    
}

@media only screen and (max-width: 600px) {
    
}
@media only screen and (max-width: 400px) {
    
}