@import '../../../globalstyled/common/sass/variables.scss';

.signup-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    background-color: #fff;
    border-radius: 4px;
    
}

.signup-login-img-wrap{
    height:100%;
    padding:0 4rem;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.signup-login-img{
    background-image: url('../../../assets/img/header/signup-logo.PNG');
    display: flex;
    min-height: 100%;
    flex-flow: column wrap;
    align-content: center; 
    background-size: contain; 
    background-repeat: no-repeat, no-repeat;
    background-position: right, left; 
    align-items: center;
}
.stack-wrapper{
    height:50px;
    width:100%;
    justify-content: space-evenly;
    align-items: flex-end;
    &.back{
        align-items: flex-start;
        & svg{
            cursor: pointer;
        }
    }
}
.btn-trig-container,.btn-forget-container{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    align-content: center;
    height:50px;
}
.signup-form-wrap{
    padding:2rem 1rem;
}
.left-logo.MuiGrid-item{
    background-color: #000;
}
.right-field.MuiGrid-item{
    min-height: 50vh;
}
.google-btn{
    margin-top:1rem;
    justify-content: center;
    display: flex;
    & button{
        & div{
            display: flex;
        }
        & span{
            font-weight: 700!important;
        }
    }
}
.sl-field.MuiTextField-root{
    width:100%;
    margin-top:1rem;
}
.txt-forget-pass.MuiButton-root, .txt-otp.MuiButton-root{
    color: #000;
    opacity: 0.4;
}
.txt-otp-wrap{
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top:10px;
}
.get-otp-btn.MuiButton-root{
    font-size:0.75rem;   
}
.forgot-txt{
    font-size: 1rem;
    font-weight: 600;
    text-align: justify;
    & span{
        font-size:0.75rem;
        font-weight:400;
    }
}
.alert-txt{
    font-size:10px;
    width:100%;
}
.text-err{
    font-size: 12px;
    color:red;
    padding: 4px 0;
}
@media only screen and (max-width: 1536px) {
    .signup-box{
        width:44%;
    }
}
@media only screen and (max-width: 1200px) {
    .signup-box{
        width:55%;
    }
}

@media only screen and (max-width: 900px) {
    .signup-box{
        width:60%;
    }
    .left-logo.MuiGrid-item{
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .signup-box{
        width:80%;
    }
}