@import '../../globalstyled/common/sass/variables.scss';



.editablebox{
    background: transparent;
    outline: none;
    border: none;
    color: $thema-primary-text-color;
    font-size: 14px;
    margin-right:2px;
    border-bottom:1px solid $thema-primary-text-color;
    width:100%;
    padding-right:30px;
}
.editablebox:focus{
    border-bottom:1px solid $thema-light
}
.editableContainer{
    display:flex;
    justify-content: space-between;
    position: relative;
    border:none;
    margin-left: 0;
    height:34px;
}
.editableicon{
    position:absolute;
    top:4px;
    right:0;
    cursor:pointer;
}
.editable-wrapper .create-icon-editable{
    opacity: 0;
    cursor: pointer;
}
.editable-wrapper:hover  .create-icon-editable{
    opacity: 0.7;
}
.head-title-search-wrapper{
    margin:8px;
}
.head-icon-box{
    display:flex;
     justify-content: flex-end;
}
.paper-table-wrapper{
    margin-bottom:16px;
    font-size:14px;
    font-weight:400;
}
.head-filter-wrapper.MuiFormControl-root, .body-check-container, .head-check-container
{
    width: 100%;
}
.filterwrapper.MuiButtonBase-root{
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: baseline;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    padding-right:4px;
    flex-wrap: nowrap;
}
.searchfield-wrapper{
    padding-left:3.5rem;
    padding-right:16px;
    text-align:left;
}
.head-price-title{
    display:flex;
    justify-content: center;
}

.table-head-wrapper.MuiGrid-root{
    flex-wrap: nowrap;
    align-items:baseline;
}

.campaign-title{
    & span{
        & a{
            color:$thema-primary-text-color;
            text-decoration: none;
        }
    }
}
.campaign-title:hover{
    & span{
        & a{
            text-decoration: none;
        }
    }
}

.dashboard-empty-section{
    margin-top:80px;
    height: 400px;
}

.tma-max-height{
    height:330px
}
.tma-normal-height{
    height:180px;
}
.tma-smalll-height{
    height:40px;
}



.option-wrapper{
    display:flex;
    justify-content: space-around;
    align-items:center;
    & .download-button-btn{
        display: flex;
    }
}

.option-menu-list-wrap{
    & .option-menu-item{
        &.delete{
            display: none;
        }
    }
}


@media only screen and (max-width: 1536px) {
    .searchfield-wrapper{
        padding-left:3rem;
    }
}
@media only screen and (max-width: 1200px) {
    .option-wrapper{
        & .download-button-btn{
            display: none;
            
        }
    }
    .option-menu-list-wrap{
        & .option-menu-item{
            &.delete{
                display: flex;
            }
        }
    }
    .option-wrapper{
        justify-content: flex-end;
    }
    .searchfield-wrapper{
        padding-left:2rem;
    }
}

@media only screen and (max-width: 900px) {
    .searchfield-wrapper{
        padding-left:1.5rem;
    }
}
@media only screen and (max-width: 700px) {
    .searchfield-wrapper{
        padding-left:1rem;
    }
}

@media only screen and (max-width: 600px) {
    .searchfield-wrapper{
        padding-left:8px;
    }
}
