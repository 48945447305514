@import "../../globalstyled/common/sass/variables.scss";

.page-title {
  font-size: 1.5rem;
  font-weight: 600;
}
.map-wrap {
}

.filterIcon {
  position: relative;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: gray;
    left: -10px;
    top: 0;
  }
  & svg {
    margin-top: 4px;
  }
}

.title-map-wrap {
  display: flex;
  justify-content: space-between;
}

.muitext.MuiFormControl-root{
  width:100%;
}

// FilterList Components

.outDfilterDNfound{
  height: 300px;
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: center;
  & p {
    margin-top:40px;
  }
}

.filter-wrapper {
  & ul {
    display: flex;
    list-style: none;
    justify-content: center;
    // overflow: hidden;
    padding: 0;
    & div {
      & li {
        display: flex;
        border-radius: 16px;
        border: 1px solid #1976d2;
        padding: 4px 6px;
        margin-right: 8px;
        cursor: pointer;

        & p {
          margin-bottom: 0;
          font-weight: 400;
          font-size: 1rem;
          opacity: 0.7;
        }
      }
    }
  }
}

.outdoor-card-image-fluid {
  height: 200px;
}

.load-more-button-wrap.MuiGrid-root {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.head-cell.MuiTableCell-root {
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: left;
}
.paper-table-wrapper {
  width: 100%;
}
.table-wrapper {
  min-width: 750px;
}
.cell-icon-wrapper {
  display: flex;
  position: relative;
}
.cell-location-icon.MuiSvgIcon-root {
  position: absolute;
  top: 0px;
  right: -6px;
  font-size: 1.25rem;
}
.discount-cell {
  text-decoration: line-through;
}
.checkicon-wrapper {
  display: inline-block;
  vertical-align: top;
}
.checkicon.MuiSvgIcon-root {
  color: #4296f3;
  font-size: 18px;
  margin-left: 5px;
}
.breadcrumbs-wrapper {
  padding-bottom: 12px;
  padding-left: 16px;
}
.main-content-wrapper {
  padding-left: 8px;
}
.head-icon-button.MuiIconButton-root {
  border-radius: 4px;
  padding: 4px;
  margin: 2px;
}
.head-icon {
  padding: 2px;
  font-size: inherit;
}
.head-icon-title {
  padding-top: 2px;
}
.head-result {
  padding: 8px 4px;
}

.breakcrumb-link {
  text-decoration: none;
  color: #000;
}
.breakcrumb-link:hover {
  color: #000;
}
.breakcrumb-link.active {
  pointer-events: none;
}
.breakcrumb-link.active:hover {
  text-decoration: none;
}
.outdoor-details-wrap {
  padding-bottom: 3rem;
  & .details-title {
    text-align: left;
    opacity: 0.7;
    font-size: 2rem;
    font-weight: 500;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }
  & .details-para {
    opacity: 0.8;
  }
  & .ol-list-wrap {
    & li {
      margin-top: 1rem;
      opacity: 0.9;
    }
  }
}
.table-body-title {
  & a {
    color: #000;
    text-decoration: none !important;
  }
}

.outdoor-item-wrap {
  margin-left: 10px;
  margin-right: 10px;
}

/* Modal */

.filtered-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 36%;
  background-color: #fff;
  border-radius: 4px;
  padding: 1rem;
}

/*Filter List */

.filter-list-wraper {
  & .notdiv.active {
    display: block;
  }
  & .notdiv {
    display: none;
  }
}

.filtersearchfield {
  width: 100%;
}

.MuiGrid-container.selection-filter-container {
    -webkit-box-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  height: 400px;
  & .selected-filter-wrap {
    & .filter-ul {
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      background-color: #a8a8a829;
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      & li {
        padding: 0.75rem 0 0.75rem 0.5rem;
        transition: all 0.2s;
        cursor: pointer;
        &.active {
          background-color: $thema-main;
          color: $thema-primary-btn-text-color;
        }
        &.active::after {
          content: "";
          width: 4px;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          background-color: $thema-dark;
          border-radius: 8px;
        }
        position: relative;
      }
      & li:first-child {
        border-top-left-radius: 0.25rem;
      }
    }
  }
}

.filter-title-container {
  // position: relative;
  // border-bottom: 2px solid red;
  & .filter-title {
    padding-bottom: 2rem;
    text-align: center;
    font-size: $fs-1five;
  }
  & .filter-close-icons{
    position: absolute;
    top:1rem;
    right:1rem;
    cursor: pointer;
    // border:1px solid gray;
    // border-radius:50%;
  }
}
.filter-title-container::after {
  content: "";
  width: 100%;
  height: 4px;
  position: absolute;
  top: 58px;
  right: 0;
  // background-color: $thema-dark;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 2px 2px 0px grey;
}

.mediacardwrapper {
  & .MuiFormControl-root.filterform {
    display: block;
  }
}

.footer-filter-container{
    justify-content: space-between;
    text-align:center;
    & div{
        margin-top:1rem;
        
    }
    & .clear-all-btn{
        width:180px;
        font-weight: 800;
    }
    & .apply-btn{
        width:180px;
        font-weight: 800;
    }
}














@media only screen and (max-width: 1536px) {
  .filtered-box {
    width: 44%;
  }
}

@media only screen and (max-width: 1200px) {
  .filtered-box {
    width: 55%;
  }
}

@media only screen and (max-width: 900px) {
  .filtered-box {
    width: 60%;
  }
}

@media only screen and (max-width: 700px) {
    .filtered-box {
        width: 77%;
      }
  .filter-wrapper {
    width: 100%;
    & ul {
      list-style: none;
      justify-content: space-evenly;
      overflow: auto;
      padding: 0;
      & div {
        display: inline-block;
        & li {
          min-width: 158px;
          margin-bottom: 1rem;
          & p {
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .filtered-box {
    width: 88%;
  }
}

@media only screen and (max-width: 500px){
    .filtered-box {
        width: 95%;
}
}

@media only screen and (max-width: 400px) {
}
