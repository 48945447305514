@import "../../globalstyled/common/sass/variables.scss";

.service-wrapper.MuiGrid-root {
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 32px;
}
.service-title.MuiTypography-root,
.client-title.MuiTypography-root {
  font-weight: bold;
  margin-bottom: 30px;
  padding: 30px 0;
  text-align: center;
}
.outdoor-title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-top: 20px;
}
.outdoor-slider-title {
  text-align: left;
  font-weight: 700;
  font-size: 2em;
}
.outdoor-slider-btn {
  text-align: right;
  & a {
    color: $thema-primary-btn-text-color;
    text-decoration: none;
  }
}
.divider-outdoot-title {
  margin: 0;
  margin-top: 0.5rem;
}

.outdoor-slider-image-fluid {
  height: 160px;
}

div.swiper {
  transition: all 1.5s;
  & .swiper-button-next {
    right: 1px;
  }
  & .swiper-button-prev {
    left: 1px;
  }
  & .swiper-button-next,
  .swiper-button-prev {
    transition: all 0.3s;
    position: absolute;
    height: 52px !important;
    width: 52px !important;
    background-color: $thema-slider-navigation-bg !important;
    box-shadow: none !important;
  }
  & .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 18px !important;
  }
  & .swiper-button-next:hover,
  .swiper-button-prev:hover {
    background-color: $thema-secondary-btn-bg-color !important;
    box-shadow: $thema-slider-navigation-shadow !important;
  }
}
.second-outdoor-slider {
  margin-top: 30px;
}

.cardcontent.MuiTypography-root {
  padding: 8px;
}
.cardcontent-outdoor.MuiTypography-root {
  &.title {
    margin-top: 20px;
  }
  padding: 4px 2px;
  text-align: left;
  margin: 0;
}
.cardcontent-outdoor.body.MuiTypography-root {
  color: $thema-gray;
}
.cardfooter-wrap {
  display: flex;
  justify-content: flex-start;
}
.pen-icon-footer.MuiSvgIcon-root {
  transform: rotate(90deg);
  color: $thema-light;
  font-size: 1.25rem;
  margin-top: 3px;
}
.card-footer-details {
  padding-left: 6px;
  font-size: 1rem;
}
.card-footer-details span {
  font-size: 0.85rem;
  color: $thema-gray;
}
.review-para.MuiTypography-root{
  padding-left:10px;
  position: relative;
}
.review-para.MuiTypography-root::after{
  content: "";
    width: 20px;
    height: 20px;
    background: url("../../assets/img/start-quote.svg");
    top: 0px;
    left: -1rem;
    position: absolute;
}

.cardcontent-wraper{
  height: 260px; 
  display: flex; 
  flex-direction: column;
  justify-content: space-between;
}

.homeoutdoorfavouriteWrapeer{
  position: relative;
}

.homeoutdoorfavouriteWrapeer .left{
  height:24px;
  // background-color: #fff;
  // background-color: #1976d2;
  position: absolute;
  top:0;
  left:0;
  z-index: 1;
  border-radius: 50%;
  // box-shadow: 0px 0px 3px 3px #fff;
}