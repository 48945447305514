.sharedContainer{
    position: fixed;
    bottom:12px;
    right:12px;
    z-index: 1;

    & .sharedBox{
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        background-color: #1976d2;
        cursor: pointer;
        box-shadow: 0px 0px 4px 2px #698cff;
        & .icond{
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            color:#fff;
            font-size:2rem;
        }
    }

}