@import '../../globalstyled/common/sass/variables.scss';

.profileinputwrapper{
    display:flex;
    flex-direction:column;
}
.profileinputwrapper > div,.profileinputwrapper > button{
    margin-bottom:10px;
    margin-top:10px;
}
.changepasswordbutton.MuiButton-root.MuiButton-contained{
    color: #000000de;
    box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024, 0px 1px 5px 0px #0000001f;
    background-color: #e0e0e0;
    padding:6px 12px;
    width:180px;
    font-weight:300;
    width:100%;
}
.backarrowpassword{
    padding: 12px 12px 12px 0;
    height: 3rem !important;
    width: 3rem !important;
    cursor: pointer;
    margin-bottom: 1rem;

}
.backarrowpassword:hvoer{
}
.changepasswordbutton.MuiButton-root.MuiButton-contained:hover{
    box-shadow: 0px 2px 4px -1px #00000033, 0px 4px 5px 0px #00000024, 0px 1px 10px 0px #0000001f;
    background-color: #d5d5d5;
}
.text-err {
    font-size: 12px;
    color: red;
    padding: 4px 0;
    &.text-user-err{
        margin-bottom:6px;
        margin-top:0;
    }
}
.updatepasswordbutton.MuiButton-root.MuiButton-contained{
    color: #000000de;
    
    background-color: #e0e0e0;
    padding:6px 12px;
    width:180px;
    font-weight:300;
}
.updatepasswordbutton.MuiButton-root.MuiButton-contained.submit{
    background-color: #2196F3;
    color:#fff;
}
.avatarimg{
    color: transparent;
    object-fit: cover;
    text-align: center;
    width: 250px;
    height: 250px;
    display: flex;
    overflow: hidden;
    position: relative;
    font-size: 1.1607142857142858rem;
    align-items: center;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
}
.avatarWrapper{
    padding-right: 20px;
    text-align:'right'
}
.profileContainer{
    padding:90px 0 160px 0;
}
.avatarmain{
    width: 100%;
    display: flex;
    justify-content: center;
    position:relative;
}
.avatardiv{
    justify-content: flex-end;
}
.avatarimgwrapper{
    width: 100%;
    display: flex;
    justify-content: center; 
    position:relative;
    transition: all 0.4;
}
.avatar-edit-icon{
    position:absolute;
    right:0;
    cursor:pointer;
}