@import "../../globalstyled/common/sass/variables.scss";

.customslider {
  div.swiper {
    transition: all 1.5s;
    & .swiper-button-next {
      right: 4px;
    }
    & .swiper-button-prev {
      left: 1px;
    }
    & .swiper-button-next,
    .swiper-button-prev {
      transition: all 0.3s;
      position: absolute;
      height: 32px !important;
      width: 32px !important;
      background-color: $thema-slider-navigation-bg !important;
      box-shadow: none !important;
    }
    & .swiper-button-next::after,
    .swiper-button-prev::after {
      font-size: 12px !important;
    }
    & .swiper-button-next:hover,
    .swiper-button-prev:hover {
      background-color: #ffffffaa !important;
      box-shadow: $thema-slider-navigation-shadow !important;
    }
  }

  & .imag-download-btn {
    position: absolute;
    right: 2px;
    top: 2px;
    z-index: 999;
    background-color: white;
    border-radius: 4px;
    border: 1px solid black;
    color: black;
    cursor: pointer;
    transition: all 1s;
    // animation: img-download-animation 0.6s linear forwards ease-in-out;
    animation: img-download-animation 0.6s linear;
  }
  @keyframes img-download-animation {
    0% {
      opacity: 0;
      font-size: 12px;
      transform: translate(-20%, 20%);
    }

    70% {
      opacity: 0.6;
    }
  }
}

.favouriteWrapper {
  display: flex;
    justify-content: end;
  width: 80%;
  text-align: right;
  padding-right: 6px;
  overflow: hidden;
  position: relative;
  margin-bottom: 0.5rem;
}

.addToFavourite {
  display: flex;
  background: white;
  padding: 4px 8px;
  border-radius: 8px;
  color: #1976d2;
  font-weight: 600;
  box-shadow: 0px 0px 2px 0px white;
  outline: none;
  cursor: pointer;
  border-color: transparent;
  align-items: center;
}
.addToFavourite p {
  margin: 0;
  font-size: 0.8rem;
}
.addToFavourite span {
  margin-left: 4px;
}
.addToFavourite span svg {
  vertical-align: middle;
  font-size:1rem;
}
