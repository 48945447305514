.slidermodal-modal-wrap{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -52%);
    width:90vw;
    height:90vh;
    background-color:#1e1e1e00;
    border-radius:4px;
    padding:1rem 1.5rem;
    text-align: center;
}

.upload-wrap-slider{
    padding-bottom:.5rem;
    text-align: right;
}

.upload-title-close{
    padding:1rem;
    padding-right: 0;
    font-weight: 800;
    font-size: 1.25rem;
    cursor: pointer;
    color:#fff;
}

.img-banner-full {
    border-radius: 6px;
    margin-bottom: 1rem;
    width: 100%;
}

.myDiv{
    width: 100%;
    height: 80vh;
    display: flex;
    border-radius: 6px;
}