@import '../../globalstyled/common/sass/variables.scss';

.accordition{
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: right;

    & button{
        // width:40px;
        // height:40px;
        margin-right:1rem;
        margin-bottom:1rem;
        
    }
    & .footer-button{
        outline: none;
        border:none;
        border-radius: 4px;
        cursor: pointer;
        background-color: $default-white;
        transition: all 0.2s;
    }
    & .footer-button{
        & svg{
            vertical-align:middle;
            padding:2px;
            color:#fff;
        }
    }
    & .footer-button:hover{
        -webkit-text-decoration: none;
        text-decoration: none;
    
    }
    & .footer-button.footer{
        margin-left:8px;
        & svg{
        color:$warning-button-primary;
        }
    }
}