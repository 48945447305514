@import '../../globalstyled/common/sass/variables.scss';

.about{
    padding:40px 0 60px 0;
}
.extra{
    height: 600px;
}
.title{
    text-align:center;
    margin-bottom: 1rem;
}
.description{
    text-align: justify;
}
.contact-us{
    // background-color: green;
    width:100%;
    // background-image: url('../../assets/img/backgradient.png');
    background-image: linear-gradient(to left top, #4e3bc9, #3a4ecf, #265dd2, #176ad3, #1976d2);
    display: flex;
    min-height: 100%;
    flex-flow: column wrap;
    align-content: center; 
    background-size: cover; 
    background-repeat:repeat;
    background-position: right, left; 
    align-items: center;
    position: relative;
    padding:1rem;
    border-radius: 1rem;
}
.contact-container{
    align-items: center;
}
.form-container{
    background-color: #fff;
    border-radius: 1rem;
    padding: 0.5rem;
    
}
.form-wrap{
    padding:0 1rem;
}
.info-container{
    margin:20px auto;
}
.info-warp{
    width:60%;
    .info{
    color:#fff;
    font-size: 26px;
    font-weight: 600;
    }
}
.img-res{
    height:70%;
    position: absolute;
    bottom: 0;
    right:3rem;
}
.MuiButton-root.submit-btn{
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.contact-title{
    margin-top:1rem;
    text-align: center;
}
.contact-field.MuiTextField-root{
    width:100%;
    margin-top:0.5rem;
}
.btn-submit{
    text-align: center;
}

.engage-container{
    align-items: stretch;
    display: flex;
}
.engage-wrapper{
    width:100%;
}
.engage{
    width:100%;
    // background-image: url('../../assets/img/backgradient.png');
    background-image: linear-gradient(to left top, #4e3bc9, #3a4ecf, #265dd2, #176ad3, #1976d2);
    border-radius: 1rem;
    padding:1.25rem 1rem;
    color:#fff;
    & .title{
        font-weight: 800;
        text-align: left;
    }
    & .sub-title{

    }
    & .description{
        color:rgb(212, 212, 212);
    }
}
.contact-info, .email-info{
    border:1px solid gray;
    border-radius: 1rem;
    padding:1rem;
}

.call-us, .mail-us{
    font-size:1.5rem;
    border-bottom: 1px solid gray;
    padding-bottom: 1rem;
    font-weight: 800;
    padding-top: 0.25rem;
}

.email-info{
    & h5{
        font-weight: 600;
        margin-bottom: 0;
    }
    & p{
        margin-bottom: 0.5rem;
        & span{
            line-height: 2;
            & svg{
                vertical-align: middle;
            }
        }
        &.time{
            color:gray;
            margin-bottom: 0.5rem;
            font-size:14px;
        }
    }
}

.call{
    & p{
        & span{
            line-height: 2;
            & svg{
                vertical-align: middle;
            }
        }
        &.time{
            color:gray;
            margin-bottom: 0.5rem;
            font-size:14px;
        }
    }
}

@media only screen and (max-width: 900px) {
    .info-container{
        position: relative;
    }

    .info-container{
        padding-top: 100px;
        height: 320px
    }
    .contact-section{
        order:2;
    }
    .info-section{
        order:1;
    }
    .img-res{
        height:90%;
        bottom:-36px;
    }
    .info-warp{
        width:90%;
        .info{
            font-size:22px;
        }
    }
}

@media only screen and (max-width: 500px) {
    .img-res{
        height:90%;
    }
    .info-container{
        padding-top: 80px;
        height: 380px
    }
    .info-container{
        padding-top:20px;
    }
    .info-warp{
        width:100%;
        .info{
            font-size:18px;
        }
    }

}