@import '../../globalstyled/common/sass/variables.scss';


.updateBtn {
    background-color: white !important;
    color: blue !important;
    border: none !important;
  }
  .fixMap {
    margin-top: -18px !important;
  }
  
  .modalTop {
    margin-top: 5% !important;
  }
  
  
  .closebtn {
    font-size: 30px;
    color: blue !important;
  }
  
  .MuiTypography-root.formedia-options{
    margin-left:1rem;
    font-weight: bold;
  }

.champaign-wrapper{
    display:flex;
    margin:auto;
    width: 88%;
    margin-top:72px;
    background-color: $main-bg-primary;
    padding:0.5em;
    border-radius: $default-border-radius;
    justify-content: center !important;
    align-items: center !important;
}
.breakcrums-right-icons{
     text-align: right;
 }
.head-icon-button.MuiIconButton-root{
    border-radius: 4px;
    padding:4px;
    margin:2px;
}
.head-icon{
    padding: 2px;
    font-size:inherit
}
.tabs-container {
    & div.MuiTabs-scroller{
    background-color: $accordian-header;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    }
}
.tabs-wrapper{
    border: 1px solid $accordian-header;
    width: 100%;
}
.tab-item.MuiButtonBase-root{
    padding:1rem 3rem;
}
.tab-grid-wrap{
    padding:2rem 1rem;
    &.campaingtabcss{
        padding:2rem 0;
    }
}
.tab-head{
    border-bottom: 1px solid;
    border-color: $default-tab-head-bottom;
}
.tab-price,.tab-status{
    color:$tab-txt-secondary;
    font-weight:500;
    font-size:0.75rem;
}
.tab-status-container.MuiGrid-item{
    display: flex;
    justify-content:center;
}
.request-button.MuiButton-root,.action-btn.MuiButton-root, .save-btn.MuiButton-root{
    & svg{
        font-size:1.25rem;
        line-height:2;
        margin-bottom:1px;
        margin-right:4px;
    }
    font-size:0.75rem;
    font-weight: 700;
    // padding:4px 4px;
}
.request-button-container.MuiGrid-item{
    justify-content: center;
    display: flex;
}
.action-btn.MuiButton-root, .save-btn.MuiButton-root{
    margin-bottom: 2px;
    background-color: #e0e0e0;
    color:#000;
}
.action-btn.MuiButton-root:hover, .save-btn.MuiButton-root:hover{
    background-color: #e0e0e0;
}
.save-btn.MuiButton-root{
    margin-left:4px;
}
.scheduler-head{
    text-align: center;
}
.scheduler-mode{
    text-align: left;
}
.action-save-wrapper{
    text-align: right;
}
.calender-media-wrapper.MuiGrid-root{
    margin-top:0.5em;
}
.media-option-wrapper {
    & .MuiAccordionSummary-expandIconWrapper{
    display: none;
    }
}
.accordion-toggle-icon{
    text-align: right;
}
.accordion-toggle-icon.rotate {
    svg{

    transform: rotate(180deg);
    }
}
.edit-button-container{
    text-align:right;
    & .edit-button{
        outline: none;
        border:none;
        border-radius: 4px;
        cursor: pointer;
        background-color: $default-button-primary;
        transition: all 0.2s;
    }
    & .edit-button{
        & svg{
            vertical-align:middle;
            padding:4px;
            color:$default-txt-primary;
        }
    }
    & .edit-button:hover{
        -webkit-text-decoration: none;
    text-decoration: none;
    background-color: #1565c0;
    box-shadow: 0px 2px 4px -1px #00000033, 0px 4px 5px 0px #00000024, 0px 1px 10px 0px #0000001f;
    }
    & .edit-button.footer{
        margin-left:2px;
    }
}
.chip-wrap{
    height: 18px;
    width: 18px;
    background: darkorange;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    font-size: 12px;
    color: #fff;
    font-weight: bold;
}
.footer-button-container{
    text-align:right;
    & .footer-button{
        outline: none;
        border:none;
        border-radius: 4px;
        cursor: pointer;
        background-color: $default-white;
        transition: all 0.2s;
    }
    & .footer-button{
        & svg{
            vertical-align:middle;
            padding:2px;
            color:$default-button-primary;
        }
    }
    & .footer-button:hover{
        -webkit-text-decoration: none;
        text-decoration: none;
    
    }
    & .footer-button.footer{
        margin-left:8px;
        & svg{
        color:$warning-button-primary;
        }
    }
}
.accordion-head-container{
    align-items: middle;
    
}
.media-option-checkbox{
    cursor: pointer;
    text-align: center;
    & input{
        cursor: pointer;
    }
}
.accordion-body-icon.MuiGrid-root{
    display: flex;
    align-items: center;
}
.body-item-checkbox{
    cursor: pointer;
    text-align: center;
    padding-left:4px;
    padding-top:4px;
    & input{
        cursor: pointer;
    }
}

.details-items{
    padding:0;
    margin:0;
    font-weight: 400;
    font-size:13px;
    line-height: 1.5;
}
.acorrdion-item-footer-icon{
    text-align: right;
}


.access-container{
    display: flex;
    justify-content: space-between;

}
.input-access.MuiFormControl-root{
    width: 100%;
}
.input-access:focus{
    border-bottom: 2px solid #5264AE;
}
.button-access.MuiButton-root{ 
    margin-left:6px;
    width:230px;
    outline: none;
}
.user-name-wrap, .user-email-wrap {
    text-align: left;
}

.user-name, .user-email, .user-action{
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
}

.user-action-wrap{
    text-align: right;
}
.table-outdoor{
    margin-top:30px;
}
.table-outdoor-campaign{
    margin-top:10px;
}
.order-divider{
    margin-top:0rem;
    margin-bottom:0.5rem;
}
.upload-modal-wrap, .request-modal-wrap{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    width:50%;
    background-color:#fff;
    border-radius:4px;
    padding:1rem 1.5rem;
    &.dattte{
        background-color: rgba(255, 255, 255, 0);
    }
}
.date-wrappi{
    justify-content: center;
}
.datereset-campaign{
    background-color: #000;
    color:red;
    padding-top: 0.3rem;
    padding-right: 0.2rem;
    padding-left: 0.2rem;
}

.edit__date__details{
    background-color: #1976d240;
    padding-left: 2rem;
    padding-top: 1rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    & p {
        &.title{
            text-align: center;
            padding-right: 2rem;
            font-size:15px;
        }
        font-size:13px;
        & span {
            font-weight: 800;
        }
    }
}
.upload-wrapper, .userslist-wrap{
    min-height:320px;
    width:100%;
}
.upload-wrap{
    padding-bottom:1.5rem;
}
.upload-container.MuiGrid-root{
    display: flex;
    max-height: calc(100% - 64px);
    flex-direction: column;
    &.dattte{
        position: relative;
    }
}
.add-cancel-btn-wrap{
    display: flex;
    justify-content: right;
}
.MuiButton-root.btn-add, .MuiButton-root.btn-cancel{
    width:90px;
    padding-right:0.5rem;
}
h4.upload-title{
    font-weight: 700;
    font-size: 1.25rem;
    
}

.upload-caption{
    padding-top:1rem;
    font-size:0.75rem;
    font-weight: 400;
    color:#878787;
    padding-bottom: 2rem;
}
.file-container,.link-container{
    display: flex;
    flex-direction: column;
    padding-bottom:2rem;
   
}
.link-container{
    padding-bottom: 4rem;
}
.file-container-btn{
    display:flex; justify-content:flex-end;
}
.title-approve{
    position: relative;
    padding:1.5rem 1rem 1rem 0;
    font-size:1.25rem;
    font-weight:600;
}
.title-approved{
    padding:0.5rem 0.5rem 0.5rem 0;
    font-size:1rem;
    font-weight:600;
}
.modal-close-btn.MuiSvgIcon-root{
    position:absolute;
    right:0;
    top:0.75rem;
    cursor: pointer;
}
.breakcrumb-link{
    text-decoration: none;
    color:#000;
}
.breakcrumb-link:hover{
    color:#000;
}
.breakcrumb-link.active{
    pointer-events: none;
}
.breakcrumb-link.active:hover{
    text-decoration: none;
}
.upload-icons{
    margin-left:1rem;
}
.action-save-container{
    display: flex;
    justify-content: right;
}
.schedular-container{
    display: flex;
    align-items: flex-start;
}
.dot-divider{
    position:absolute;
    top: 0;
    right: 3px;
    font-size:0.75rem;
}
.details-list{
    font-size:0.85rem;
    margin-bottom:0.5rem;
    font-weight:400;
    &.second{
        text-align: right;
    }
}
.details-title{
    text-align: center;
    font-weight:500;
    font-size:1.25rem;
}


.uploadtablesection{
    & .table-outdoor-campaign{
        border-bottom:1px solid rgba(0, 0, 0, 0.11);
    }
}









@media only screen and (max-width: 900px) {
    .champaign-wrapper{
        display:flex;
        margin:auto;
        width: 98%;
        margin-top:72px;
        background-color: $main-bg-primary;   
    }
    .action-save-wrapper{
        text-align: center;
    }
    .scheduler-mode{
        text-align: center;
    }
    .accordion-body-icon.MuiGrid-root{
        justify-content: center;
    }
    .body-item-checkbox{
        padding-left:0.75em;
        
    }
    .request-button-container.MuiGrid-item{
        justify-content: left;
    }
    .save-btn.MuiButton-root{
        margin-left:12px;
    }
    .action-save-container{
        justify-content: right;
    }
    .tab-status-container.MuiGrid-item{
        justify-content: right;
    }
    
}

@media only screen and (max-width: 600px) {
    .tab-item.MuiButtonBase-root{
        padding:1rem 0.5rem;
    }
    .body-item-checkbox{
        padding-left:0.25em;
        
    }
    .upload-modal-wrap,.request-modal-wrap{
        width:80%
    }
    .tab-status-container.MuiGrid-item{
        justify-content: left;
    }
    
    .request-button-container.MuiGrid-item{
        justify-content: center;
    }
    .action-save-container{
        justify-content: left;
    }
}
@media only screen and (max-width: 400px) {
    .request-button.MuiButton-root{
        padding:3px 4px;
    }
    .access-container{
        display:block;
        & .button-access.MuiButton-root{
            margin-top:10px;
        }
    }
}


@media (min-width:576px){
    .modal-dialog{
        max-width: 100vw !important;
        margin: 1.75rem auto !important;
        margin-left: 1% !important;
        margin-right: 1% !important;
    }
}

