@import '../../globalstyled/common/sass/variables.scss';

.title {
  text-align: left;
  font-weight: 800;
  font-size: 30px;
  padding-left: 8px;
}
.MuiGrid-item.hero-wrap {
  margin: 0 10px 0 24px;
  border: 1px solid gray;
  border-radius: 6px;
}
.hero-section {
  align-items: center;
  background-color: #1976d2;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.hero-section2 {
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.hero-container {
  display: flex;
  color: #fff;
}

.image-container {
  width: 50%;
}

.img-banner {
  border-radius: 6px;
  height: 170px;
  width: 100%;
  margin-bottom:1rem
}

.desc-container {
  width: 50%;
}
.desc-icon {
  width: 100%;
  padding-right: 6px;
  display: flex;
  // align-items: center;
  & .desction {
    width: 80%;
    text-align: right;
    padding-right: 6px;
    overflow: hidden;
    position: relative;
    margin-bottom: 0.5rem;
    & h4 {
    }
    & p {
      text-transform: uppercase;
      font-size: 0.75rem;
      margin-bottom: 0.5rem;
    }
  }
  & .desction.dash:after {
    content: "";
    border-bottom: 2px dashed #fff;
    height: 10px;
    width: 100px;
    position: absolute;
    right: 8px;
    bottom: 0;
    opacity: 0.5;
  }
  & .icon {
    width: 20%;
    overflow: hidden;
    & svg {
      font-size: 2rem;
      background-color: #468ed6;
      padding: 4px;
      border-radius: 6px;
    }
  }
}

.desc-wrap {
  color: #fff;
}
.about-title {
}
.about-desc {
  opacity: 0.6;
}

.heading {
  align-self: flex-start;
  padding-right: 1rem;
  text-align: center;
  padding-bottom:2rem;
  & h4 {
    font-size: 22px;
    font-weight: 800;
  }
}

.key-insight {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & .desc-wrapp {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-right: 20px;
    justify-content: space-between;
    & .description {
      padding-right: 1rem;
      padding-left: 1rem;
      position: relative;
      & h5 {
        opacity: 0.6;
        font-size: 0.75rem;
        text-transform: uppercase;
        margin-bottom: 0;
      }
      & p {
        font-weight: 600;
        margin-bottom: 0.5rem;
        font-size: 1.1rem;
      }
    }
    & .description::after {
      content: "";
      background-image: linear-gradient(
        to bottom,
        #ffffff,
        #e3e2f6,
        #c4c6ed,
        #a1ace5,
        #7894dd,
        #7894dd,
        #7894dd,
        #7894dd,
        #a1ace5,
        #c4c6ed,
        #e3e2f6,
        #ffffff
      );
      border-bottom: 2px dashed #fff;
      height: 100%;
      width: 4px;
      position: absolute;
      right: 0px;
      top: -4px;
      opacity: 0.5;
    }
  }
}

.offer-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  & .card-wrapper {
    display: flex;
    margin: 2px;
    & .card-box {
      width: 50%;
      text-align: center;
      border: 1px solid gray;
      border-radius: 6px;
      margin: 2px 12px;
      padding: 8px 4px;
      &.bag {
        display: flex;
        align-items: center;
        justify-content: center;
        &.disabled{
          cursor: not-allowed;
          background-color:#ccc;
          border-color:#ccc;
        }
        & span {
          font-size: 1rem;
          padding-left: 4px;
        }
      }
      & p {
        margin-bottom: 0.5rem;
        font-weight: 600;
      }
      & h4 {
        margin-bottom: 0.5rem;
        font-size: 1.25rem;
        &.strick {
          color: red;
          text-decoration: line-through;
          & span {
            color: #000;
          }
        }
      }

      & h5 {
        margin-bottom: 0.5rem;

        font-size: 1.25rem;
        & span {
          font-size: 1.5rem;
          font-weight: 600;
        }
      }
      &.offer {
        background-color: #56ca85;
        position: relative;
      }
      &.offer::after {
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        background: transparent
          url("/assets/img/offercard.png")
          no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        background-size: 40px;
      }
    }
  }
  & .card-wrapper:nth-child(1) {
    margin-bottom: 2rem;
  }
}

.offer-info {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  & p {
    margin-bottom: 0;
    color: red;
  }
  & svg {
    color: red;
  }
}

.MuiGrid-root.detailing-section {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.item-wrapper {
  & .MuiPaper-root {
    & .MuiAccordionSummary-root {
      background-color: rgba(73, 159, 222, 0.1);
      & .MuiAccordionSummary-content {
        justify-content: center;
        & p {
          font-size: 1.5rem;
          color: #499fde;
          font-weight: 700;
        }
      }
      & .MuiAccordionSummary-expandIconWrapper {
        & svg {
          color: #499fde;
        }
      }
    }
  }

  & .item-details-list {
    & ul {
      padding: 0;
      list-style: none;
      margin: 0;
      & li {
        border-bottom: 1px solid rgba(128, 128, 128, 0.534);
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
        & h6 {
          margin-bottom: 0;
          font-size: 1rem;
        }
        & p {
          margin-bottom: 0.25rem;
          opacity: 0.6;
          font-size: 0.75rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 600px) {
  .desc-icon {
    & .icon {
      & svg {
        font-size: 2rem;
      }
    }
  }
}
@media only screen and (max-width: 400px) {
}
