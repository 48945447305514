@import '../../../globalstyled/common/sass/variables.scss';

.stage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 8rem 0 22rem 0;
    margin: 0;
    overflow: hidden;
    width:100%;
  }
/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
 .dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $thema-main;
    color: $thema-main;
    box-shadow: 9999px 0 0 -5px $thema-main;
    animation: dotPulse 1.5s infinite linear;
    animation-delay: .25s;
  }
  
  .dot-pulse::before, .dot-pulse::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $thema-main;
    color: $thema-main;
  }
  
  .dot-pulse::before {
    box-shadow: 9984px 0 0 -5px $thema-main;
    animation: dotPulseBefore 1.5s infinite linear;
    animation-delay: 0s;
  }
  
  .dot-pulse::after {
    box-shadow: 10014px 0 0 -5px $thema-main;
    animation: dotPulseAfter 1.5s infinite linear;
    animation-delay: .5s;
  }
  
  @keyframes dotPulseBefore {
    0% {
      box-shadow: 9984px 0 0 -5px $thema-main;
    }
    30% {
      box-shadow: 9984px 0 0 2px $thema-main;
    }
    60%,
    100% {
      box-shadow: 9984px 0 0 -5px $thema-main;
    }
  }
  
  @keyframes dotPulse {
    0% {
      box-shadow: 9999px 0 0 -5px $thema-main;
    }
    30% {
      box-shadow: 9999px 0 0 2px $thema-main;
    }
    60%,
    100% {
      box-shadow: 9999px 0 0 -5px $thema-main;
    }
  }
  
  @keyframes dotPulseAfter {
    0% {
      box-shadow: 10014px 0 0 -5px $thema-main;
    }
    30% {
      box-shadow: 10014px 0 0 2px $thema-main;
    }
    60%,
    100% {
      box-shadow: 10014px 0 0 -5px $thema-main;
    }
  }
  