@import '../../globalstyled/common/sass/variables.scss';

.container {
  padding-left: 0;
  padding-right: 0;
}

.unorder {
  margin-left: -12px;
}

.textBold {
  font-size: 20pt;
  font-weight: bold;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.loadmore {
  width: 200px;
  background-color: #4e3bc9 !important ;
}

.mapStyle {
  display: flex !important;
  flex-direction: column !important;
  height: 70vh !important;
  /* width: 44vw !important; */
}

.mapStyle2 {
  margin-top: 4% !important;
  height: 70vh !important;
  width: 88vw !important;
}

.mapDiv {
  margin-left: 14% !important;
}

@media (max-width: 1660px) {
  .mapDiv {
    margin-left: 26% !important;
  }
  .loadmore {
    width: 20% !important;
  }
}

@media (max-width: 781px) {
  .loadmore {
    width: 150px !important;
    font-size: 12px !important;
  }
}
